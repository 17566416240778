import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guard/auth.guard';
import { BaseComponent } from './views/layout/base/base.component';
const routes: Routes = [
  {
    path:'',redirectTo:'auth',pathMatch:'full'
  },
  { path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule) }
  ,
  {
    path: 'base',
    component: BaseComponent,
    children: [
      {
        canActivate: [AuthGuard],
        path: 'dashboard',
        loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        canActivate: [AuthGuard],
        path:'patient',
        loadChildren: () => import('./module/patient/patient.module').then(m => m.PatientModule)
      },
      {
        canActivate: [AuthGuard],
        path:'doctor',
        loadChildren: () => import('./modules/doctor/doctor.module').then(m => m.DoctorModule)
      },
      {
        canActivate: [AuthGuard],
        path:'clinic',
        loadChildren: () => import('./clinic/clinic.module').then(m => m.ClinicModule)
      },
      {
        canActivate: [AuthGuard],
        path:'hospital',
        loadChildren: () => import('./hospital/hospital.module').then(m => m.HospitalModule)
      },
      {
        canActivate: [AuthGuard],
        path:'speciality-hospital',
        loadChildren: () => import('./speciality-hospital/speciality-hospital.module').then(m => m.SpecialityHospitalModule)
      },      
      {
        canActivate: [AuthGuard],
        path:'pharmacy',
        loadChildren: () => import('./pharmacy/pharmacy.module').then(m => m.PharmacyModule)
      },
      {
        canActivate: [AuthGuard],
        path:'diagnostic-lab',
        loadChildren: () => import('./diagnostic-lab/diagnostic-lab.module').then(m => m.DiagnosticLabModule)
      },
      {
        canActivate: [AuthGuard],
        path:'wellness',
        loadChildren: () => import('./wellness/wellness.module').then(m => m.WellnessModule)
      },
      {
        canActivate: [AuthGuard],
        path:'homecare',
        loadChildren: () => import('./homecare/homecare.module').then(m => m.HomecareModule)
      }
    ]
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
