import { Injectable } from '@angular/core';
@Injectable()
export class GlobalStorage {
    constructor() { }

    get_user_id() {
        return localStorage.getItem('user_id');
    }
    get_user_name() {
        return localStorage.getItem('user_name');
    }
    get_user_type() {
        return localStorage.getItem('user_type');
    }
    get_member_id() {
        return localStorage.getItem('member_id');
    }
}