import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs'
import { UserService } from '../../views/pages/auth/UserDetails/user.service';
import { Router } from '@angular/router';
import { retry } from 'rxjs/operators';
import { Notification } from '../Notifications/Notification';
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: UserService, private router: Router, private Notify: Notification) { }

    // intercept(request: HttpRequest<any>, newRequest: HttpHandler): Observable<HttpEvent<any>> {

    //     return newRequest.handle(request).pipe(catchError(err => {
    //         if (err.status === 401) {
    //             //if 401 response returned from api, logout from application & redirect to login page.
    //           //  console.log("logout");
    //             this.authenticationService.logout();
    //         }

    //         const error = err.error.message || err.statusText;
    //         alert(error);
    //         return Observable.throw(error);
    //     }));
    // }
    // intercept(request: HttpRequest<any>, next: HttpHandler) {

    //     return next.handle(request)
    //         .pipe(
    //             catchError((error: HttpErrorResponse) => {
    //                 let errorMessage = '';
    //                 if (error.error instanceof ErrorEvent) {
    //                     // client-side error
    //                     errorMessage = `Error: ${error.error.message}`;
    //                 } else {
    //                     // server-side error
    //                     errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;
    //                 }
    //                 if (error.status === 401) {
    //                     this.Notify.errorsmsg("Unauthorized Access")
    //                     this.authenticationService.logout();
    //                 }
    //                 else {
    //                     this.Notify.errorsmsg(errorMessage);
    //                 }
    //                 return throwError(errorMessage);
    //             })
    //         )
    // }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        console.log('Http request started');
        return next.handle(req)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    // console.log('data')
                    // console.log(error);
                    if (error.status == 400 && error.error.error_description !=undefined ) {
                        this.Notify.errorsmsg(error.error.error_description);
                    }
                    else if (error.status == 400 && error.error != undefined) {
                        console.log('API Error')
                        console.log(error.error)
                        if (error.error.message != undefined) {
                            this.Notify.errorsmsg(error.error.message);
                        }
                        else if (error.error != undefined) {
                            this.Notify.errorsmsg(error.error);
                        }
                        else
                            this.Notify.errorsmsg(error.error.message);
                    }
                    else if (error.status == 400) {
                        console.log('API Error')
                        console.log(error.error)
                        this.Notify.errorsmsg("Duplicate value are not allowed");
                    }
                    else if (error.status == 404) {
                        console.log('Test')
                        console.log(error)
                        this.Notify.errorsmsg("Data not found");
                    }
                    else if (error.status == 404 && error.error==undefined) {
                        console.log(error.error.error_description)
                        this.Notify.errorsmsg("Data not found");
                    }
                    else if (error.status == 405) {
                        this.Notify.errorsmsg("The requested URL couldn't be found or that it was entered incorrectly");
                    }
                    else if (error.status == 500) {
                        this.Notify.errorsmsg("Please contact: enquiry@vikasglobal.net");
                    }
                    
                    // else {
                    //     this.Notify.errorsmsg(error.error)
                    // }
                    return throwError(error.error)
                })
            );
    }
}